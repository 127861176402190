import './devicetable.scss'
import { DataGrid } from '@mui/x-data-grid'
import { deviceColumns } from '../../datatablesource'
import { ref, child, get } from 'firebase/database'
import { useEffect, useState } from 'react'
import { database } from '../../firebase'
import { useNavigate } from 'react-router-dom'

const Devicetable = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      let list = []

      const dbRef = ref(database)
      get(child(dbRef, `product_class_group`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            // console.log(snapshot.val());
            snapshot.val().forEach((doc) => {
              // doc.imei = "";
              // doc.osname = "";
              // doc.devicetype = "";
              // doc.ipaddress = "";

              list.push({
                imei: '',
                osname: '',
                devicetype: '',
                ipaddress: '',
                ...doc,
              })
            })

            setData(list)
          } else {
            console.log('No data available')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    fetchData()
  }, [])

  // const actionColumn = [
  //     {
  //         field: 'action',
  //         headerName: 'Action',
  //         width: 200,
  //         renderCell: () => {
  //             return (
  //                 <div className="cellAction">
  //                     <div className="viewButton">View</div>
  //                     <div className="deleteButton">Delete</div>
  //                 </div>
  //             );
  //         },
  //     }
  // ]

  const redirectToDetails = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    event.preventDefault()
    // console.log('Test =======')
    // console.log(params)
    const url = `/devices/${params.row.id}`
    // console.log(url)
    navigate(url)

    // const navigate = useNavigate()
    // useEffect(() => {
    //   navigate.push({
    //     pathname: '/secondpage',
    //     search: '?query=abc',
    //   })
    // }, [navigate])

    // console.log(params)
  }

  return (
    <div className="devicetable">
      <DataGrid
        rows={data}
        // columns={userColumns.concat(actionColumn)}
        columns={deviceColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        // onRowClick={console.log('click row')}
        disableSelectionOnClick
        onRowClick={redirectToDetails}
      />
    </div>
  )
}

export default Devicetable

// npm i react-router-dom@6.4.1
