import { useParams, useNavigate } from 'react-router-dom'
import { ref, child, get } from 'firebase/database'
import { useEffect, useState } from 'react'
import { database } from '../../firebase'
import './device.scss'
// MUI
import { Box } from '@mui/system'
import { Tab, Tabs } from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
// Tab icons
import { RiSettings4Line, RiRefreshLine } from 'react-icons/ri'
import { BiWrench } from 'react-icons/bi'
// Tabs
import DeviceSettingsTab from './DeviceSettingsTab'
import DeviceMainTab from './DeviceMainTab'
// firebase
import { onValue, orderByChild, equalTo, limitToLast } from 'firebase/database'
import { query } from 'firebase/firestore'

import { setGlobalState, useGlobalState } from '../../state'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

//https://stackoverflow.com/questions/72501808/useglobalstate-resetting-when-i-navigate
const initialStateSaved =
  window.sessionStorage.getItem('reducer') &&
  JSON.parse(window.sessionStorage.getItem('reducer'))

const Device = () => {
  // console.log('Device')
  // const [device, setDevice] = useState(undefined)
  const params = useParams()
  const navigate = useNavigate()
  var [cpeParamNames] = useGlobalState('cpe_parameter_name')
  if (!cpeParamNames.length && initialStateSaved) {
    cpeParamNames = initialStateSaved
  }

  // console.log('=== CPE PARAMETER NAMES')
  // console.log(cpeParamNames)
  // console.log('=======|')
  // console.log(initialStateSaved)
  // console.log('=======|')

  //tabs
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [cpeId, setCpeId] = useState(-1)

  // get latest cpe row, by product_class_group.id == cpe.group_id
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database, 'cpe')

      const mostViewedPosts = query(
        dbRef,
        orderByChild('group_id'),
        equalTo(Number(params.id)),
        limitToLast(1)
      )

      onValue(mostViewedPosts, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          // console.log('CPE ID fetched: ' + childData.id)
          setCpeId(Number(childData.id))
          // ...
        })
      })
    }
    fetchData()
  }, [params.id])

  // if ()
  // const [paramNames, setParamNames] = useState([])

  // get rows from cpe_parameter, where cpe_id == cpe.id AND param_id == cpe_parameter_name.id
  const [paramValues, setParamValues] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      // console.log('Select items from cpe_parameter for cpeId: ' + cpeId)
      const dbRef = ref(database, 'cpe_parameter')
      const paramList = query(dbRef, orderByChild('cpe_id'), equalTo(cpeId))

      // console.log('paramValues length: ' + paramValues.length)

      onValue(paramList, (snapshot) => {
        var cpeParameterValues = []
        snapshot.forEach((childSnapshot) => {
          cpeParameterValues.push(childSnapshot)
        })
        setParamValues(cpeParameterValues)
        // console.log(
        //   'CPE_PARAMETER Completed! Witch cpe id: ' +
        //     cpeId +
        //     ' and param length: ' +
        //     cpeParameterValues.length
        // )
        // console.log('Param Values:')
        // console.log(cpeParameterValues)
      })
    }
    fetchData()
  }, [cpeId])

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database)
      const refId = Number(params.id) - 1
      console.log('params.id', refId)
      get(child(dbRef, `product_class_group/${refId}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log('====>')
            console.log(snapshot.val())

            setGlobalState('manufacturer', snapshot.val().manufacturer)
            setGlobalState('model', snapshot.val().model)

            window.sessionStorage.setItem(
              'reducer_manufacturer',
              snapshot.val().manufacturer
            )

            window.sessionStorage.setItem('reducer_model', snapshot.val().model)
            // setDevice(snapshot.val())
          } else {
            console.log('No data available')
            navigate('..', { relative: 'path' })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    fetchData()
  }, [params.id, navigate])

  // console.log('Return main device')

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            icon={<BiWrench />}
            iconPosition="start"
            label="Main"
            {...a11yProps(0)}
          />
          <Tab
            icon={
              cpeParamNames.length === 0 ? (
                <RiRefreshLine />
              ) : (
                <RiSettings4Line />
              )
            }
            iconPosition="start"
            label={cpeParamNames.length === 0 ? 'Loading' : 'Device Settings'}
            disabled={cpeParamNames.length === 0}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {/* Main, key icon */}
      <TabPanel value={value} index={0}>
        <DeviceMainTab
          cpeParameterNames={cpeParamNames}
          cpeParameterValues={paramValues}
        />
      </TabPanel>

      {/* Device Settings, gauge icon */}
      <TabPanel value={value} index={1}>
        <DeviceSettingsTab tree={cpeParamNames} cpeId={cpeId} />
      </TabPanel>
    </div>
  )
}

export default Device
