import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import Stack from '@mui/material/Stack'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import DeleteIcon from '@mui/icons-material/Delete'
import LockResetIcon from '@mui/icons-material/LockReset'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'
import RouterIcon from '@mui/icons-material/Router'

function ToolsCard({ title }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            T
          </Avatar>
        }
        title={title}
        subheader="Device action button list"
      />
      <CardContent sx={{ minHeight: 350 }}>
        <Stack direction="row" spacing={3}>
          <Stack spacing={2} alignItems="flex-start">
            <Button startIcon={<RestartAltIcon />} color="warning">
              Restart device
            </Button>
            <Button startIcon={<LockResetIcon />} color="warning">
              Factory reset
            </Button>
            <Button startIcon={<DeleteIcon />} color="warning">
              Delete device
            </Button>
            <Button startIcon={<ContentPasteSearchIcon />} color="secondary">
              Start tracing
            </Button>
          </Stack>
          <Stack spacing={2} alignItems="flex-start">
            <Button startIcon={<WysiwygIcon />} color="secondary">
              View tracing
            </Button>
            <Button startIcon={<SettingsRemoteIcon />}>Remote access</Button>
            <Button startIcon={<RouterIcon />}>Reprovision</Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ToolsCard
