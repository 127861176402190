import './forgot.scss'

const Forgot = () => {
  return (
    <>
      <header className="sc-jIAOiI haFYRS">
        <div className="sc-ZyCDH ARXcN">
          <a className="sc-idiyUo Vxrme sc-TRNrF kiQvRe" href="/">
            Spartan<span>Shield</span>
          </a>
        </div>
      </header>

      <div class="sc-dUWWNf dHbgqB">
        <div class="sc-bczRLJ dDRJpc sc-hKMtZM jtrHBG container">
          <div class="sc-jhfVAM dupzNu">
            <div class="sc-enyVUO zXDlL">
              <img src="/images/forgot.png" alt="forgot password" />
            </div>

            <h4 class="sc-bwANAz kLRygp">Reset your password</h4>
            <p class="sc-ekGZSs jiaskw">
              Enter your username or email address and we will send you a link
              to reset your password.
            </p>
            <div class="sc-btIRyd kniyBm">
              <input
                type="email"
                class="sc-iNWwEs fgGdWf form-control"
                id="email"
                name="email"
                placeholder="Enter username or email address"
                value=""
              />
              <button type="submit" class="sc-fnykZs iiGwAM btn">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer className="sc-jQHtVU igAodF">
        <div className="sc-fvNpTx eeTpIf">
          <img
            className="logo-m"
            src="/images/spartan-shield-logo-m.png"
            alt="Spartan Shield"
          />
          <span>SpartanShield Technologies 2023</span>
        </div>
      </footer>
    </>
  )
}

export default Forgot
