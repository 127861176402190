import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import {
  ref,
  onValue,
  orderByChild,
  equalTo,
  set,
  limitToLast,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { database } from '../../firebase'
import { query } from 'firebase/firestore'

import EditIcon from '@mui/icons-material/Edit'
import SendIcon from '@mui/icons-material/Send'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
}

// product_class_group -> id = params.id
function ParamsDeviceTable({ node, cpeId }) {
  // const [rows, setRows] = useState([])
  // const [cpeValues, setCpeValues] = useState([])
  // const params = useParams()
  // const [cpeId, setCpeId] = useState(-1);

  const [disabled, setDisabled] = useState(true)
  const [inputFields, setInputFields] = useState([])

  // console.log('PARAMSDEVICETABLE: ' + cpeId)

  // function createData(rowId, pname, pvalue, valueid) {
  function createData(
    rowId,
    pname,
    created,
    cpeParamId,
    cpeParamIdParamId,
    pvalue
  ) {
    return { rowId, pname, created, cpeParamId, cpeParamIdParamId, pvalue }
  }

  const getDateInFormat_yyyy_mm_dd_hh_mm_ss = () => {
    const toString = (number, padLength) => {
      return number.toString().padStart(padLength, '0')
    }

    const date = new Date()

    const dateTimeNow =
      toString(date.getFullYear(), 4) +
      '-' +
      toString(date.getMonth() + 1, 2) +
      '-' +
      toString(date.getDate(), 2) +
      ' ' +
      toString(date.getHours(), 2) +
      ':' +
      toString(date.getMinutes(), 2) +
      ':' +
      toString(date.getSeconds(), 2)
    return dateTimeNow
  }

  // cpe_parameter
  // cpe_id:7
  // created:"2015-06-24 05:28:48"
  // id:9943
  // param_id:424
  // updated:"2015-06-24 05:28:48"
  // value:""
  const sendUpdate = () => {
    if (!disabled) {
      const dbRef = ref(database, 'cpe_parameter')
      const paramList = query(dbRef, limitToLast(1))

      onValue(
        paramList,
        (snapshot) => {
          if (snapshot.exists()) {
            var key = Number(Object.keys(snapshot.val())[0])
            console.log('Last row in cpe_param: ' + key)

            //save update
            inputFields.forEach((child) => {
              if (child.cpeParamId >= 0) {
                //update firebase db item
                console.log(
                  'Trying to update: cpe_parameter: ' +
                    child.rowId +
                    ', cpe_id:' +
                    cpeId +
                    ', created:' +
                    child.created +
                    ', id:' +
                    child.cpeParamId +
                    ', param_id:' +
                    child.cpeParamIdParamId +
                    ', value:' +
                    child.pvalue
                )

                set(ref(database, 'cpe_parameter/' + child.rowId), {
                  cpe_id: cpeId,
                  created: child.created,
                  id: child.cpeParamId,
                  param_id: child.cpeParamIdParamId,
                  updated: getDateInFormat_yyyy_mm_dd_hh_mm_ss(),
                  value: child.pvalue,
                })
                  .then(() => {
                    console.log(
                      'Update cpe_parameter: ' +
                        child.rowId +
                        ', Data saved successfully!'
                    )
                  })
                  .catch((error) => {
                    console.log(
                      'Update cpe_parameter: ' +
                        child.rowId +
                        ', The write failed...'
                    )
                    console.log(error)
                  })
                //end
              } else {
                //add firebase db item
                key += 1

                console.log(
                  'Trying to add: cpe_parameter: ' +
                    key +
                    ', cpe_id:' +
                    cpeId +
                    ', created:' +
                    child.created +
                    ', id:' +
                    getRandomInt(550000, 2000000) +
                    ', param_id:' +
                    child.cpeParamIdParamId +
                    ', value:' +
                    child.pvalue
                )

                //TODO: cpe_parameter.id - not used for firebase table, otherwise, remove all rows where id > 543314
                let paramsToAdd = {
                  cpe_id: cpeId,
                  created: child.created,
                  id: getRandomInt(550000, 2000000),
                  param_id: child.cpeParamIdParamId,
                  updated: getDateInFormat_yyyy_mm_dd_hh_mm_ss(),
                  value: child.pvalue,
                }

                set(ref(database, 'cpe_parameter/' + key), paramsToAdd)
                  .then(() => {
                    console.log(
                      'Add cpe_parameter: ' +
                        child.pname +
                        '/' +
                        child.pvalue +
                        ', Data saved successfully!'
                    )
                  })
                  .catch((error) => {
                    console.log(
                      'Add cpe_parameter: ' +
                        child.pname +
                        '/' +
                        child.pvalue +
                        ', The write failed...'
                    )
                    console.log(error)
                  })
                //end
              }
            })
            // end
          }
        },
        {
          onlyOnce: true,
        }
      )
    }

    setDisabled(!disabled)
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields]
    data[index].pvalue = event.target.value
    setInputFields(data)
  }

  // get rows from cpe_parameter, where cpe_id == cpe.id AND param_id == cpe_parameter_name.id
  useEffect(() => {
    const fetchData = async () => {
      // console.log('Select items from cpe_parameter for cpeId: ' + cpeId)

      const dbRef = ref(database, 'cpe_parameter')

      const paramList = query(
        dbRef,
        orderByChild('cpe_id'),
        equalTo(cpeId)
        // orderByChild('param_id'),
        // equalTo(Number(node.id))
      )

      onValue(paramList, (snapshot) => {
        // console.log('Params count: ')
        // console.log(Object.keys(snapshot.val()).length)
        // console.log(snapshot)

        var cpeParameterValues = []

        // console.log("GET SNAPSHOT")

        snapshot.forEach((childSnapshot) => {
          // console.log(childSnapshot)
          // const childKey = childSnapshot.key;
          const childData = childSnapshot.val()
          if (childData.cpe_id === cpeId) {
            // console.log(childSnapshot.key + ' - ' + childData.id)
            // {cpe_id: 32, created: '2015-08-17 12:17:54', id: 34856, param_id: 207, updated: '2015-08-17 12:17:54', value: ?}
            cpeParameterValues.push(childSnapshot)
          }
        })

        // console.log('CPE PARAMS for cpeId: ' + cpeId)
        // console.log(cpeParameterValues.filter((v) => v.value))
        // setCpeValues(cpeParameterValues)

        var rows = []
        // console.log(node.children)
        if (node.children && node.children.length > 0) {
          // console.log("NODE CHILD")
          // console.log(node)
          // console.log(cpeValues)
          node.children.forEach((child) => {
            // console.log(child)
            if (child.format === 'chr') {
              var rowId = -1
              var created = getDateInFormat_yyyy_mm_dd_hh_mm_ss()
              var cpeParamId = -1 // if add new value, should be overriden later
              var cpeParamIdParamId = child.id
              var cpeValue = ''

              // console.log(child)

              const foundParam = cpeParameterValues.filter(
                (cpeParameter) => cpeParameter.val().param_id === child.id
              )
              // console.log("param found: ")
              // console.log(foundParam[0].val())
              if (foundParam[0] && foundParam[0].val() && foundParam[0].key) {
                // if (foundParam && foundParam.length > 0 && foundParam[0].value) {
                // console.log(foundParam[0].val())
                // console.log('id: ' + foundParam.id + 'j, param_id: ' + foundParam.param_id)

                // created: "2019-01-30 20:39:54"
                // id: 280933
                // param_id: 708
                // value: "Android"

                rowId = Number(foundParam[0].key)
                created = foundParam[0].val().created
                cpeParamId = foundParam[0].val().id
                cpeParamIdParamId = foundParam[0].val().param_id
                cpeValue = foundParam[0].val().value

                // console.log('Row value ===>: ' + rowValue)
              }
              // console.log('Row value: ' + rowValue)
              // rows.push(createData(rowId, child.name, cpeValue, cpeParamId))
              rows.push(
                createData(
                  rowId,
                  child.name,
                  created,
                  cpeParamId,
                  cpeParamIdParamId,
                  cpeValue
                )
              )
            }
          })
          // console.log('ROWWWWWWS===============')
          // console.log(rows)
          setInputFields(rows)
        }
      })
    }
    fetchData()
  }, [cpeId, node.id])

  return (
    <>
      {node.full_name.length > 0 && (
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Box align="left" sx={{ p: 2, mb: 2, border: '1px dashed grey' }}>
            <Typography>
              {node.full_name} - {node.id} - cpe id: {cpeId}
            </Typography>
          </Box>
          <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
            <Box>
              <Button
                sx={{ p: 1 }}
                color={disabled ? 'secondary' : 'primary'}
                variant="contained"
                endIcon={disabled ? <EditIcon /> : <SendIcon />}
                onClick={sendUpdate}
              >
                {disabled ? 'Edit' : 'Save'}
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {inputFields.length > 0 && (
            <TableHead>
              <TableRow>
                <StyledTableCell>Parameter name</StyledTableCell>
                <StyledTableCell>Parameter value</StyledTableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {inputFields.map((row, index) => (
              <StyledTableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.pname}
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    disabled={disabled}
                    id="outlined-disabled"
                    label={disabled ? 'Disabled' : row.pname}
                    value={row.pvalue}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ParamsDeviceTable
