import Grid from '@mui/material/Grid'
// Cards db
import cards from '../../db/cards'
import DeviceStatusCard from '../../components/card/DeviceStatusCard'
import DeviceHistoryCard from '../../components/card/DeviceHistoryCard'
import DefaultCard from '../../components/card/DefaultCard'
import ToolsCard from '../../components/card/ToolsCard'
import DeviceInfoCard from '../../components/card/DeviceInfoCard'
import ConnectivityCard from '../../components/card/ConnectivityCard'
import StorageCard from '../../components/card/StorageCard'
import ExploitsMalwareCard from '../../components/card/iotSecurity/ExploitsMalwareCard'
import VulnerabilityCard from '../../components/card/iotSecurity/VulnerabilityCard'
import ContextCard from '../../components/card/iotSecurity/ContextCard'

function fetchCardValues(paramNames, paramValues, parentName, childName) {
  var cardItems = []
  if (paramNames && paramNames[0] && paramValues) {
    const deviceNode = paramNames[0].children.filter(
      (param) => param.name === parentName
    )

    if (deviceNode[0] && deviceNode[0].children) {
      const deviceStatusNode = deviceNode[0].children.filter(
        (param) => param.name === childName
      )
      if (deviceStatusNode[0] && deviceStatusNode[0].children) {
        deviceStatusNode[0].children.forEach((pname) => {
          const foundParam = paramValues.filter(
            (cpeParameter) => cpeParameter.val().param_id === pname.id
          )
          if (foundParam[0] && foundParam[0].val()) {
            cardItems.push({
              name: pname.name,
              value: foundParam[0].val().value,
            })
          }
        })
      }
    }
  }
  return cardItems
}

function renderSwitch(id, title, description, paramNames, paramValues) {
  switch (id) {
    case 3:
      var cardItems = []
      if (paramNames && paramNames[0] && paramValues) {
        const di = paramNames[0].children.filter(
          (param) => param.full_name === './OSInfo'
        )

        if (di && di[0].children) {
          di[0].children.forEach((pname) => {
            const foundParam = paramValues.filter(
              (cpeParameter) => cpeParameter.val().param_id === pname.id
            )
            if (foundParam[0] && foundParam[0].val()) {
              cardItems.push({
                name: pname.name,
                value: foundParam[0].val().value,
              })
            }
          })
        }
      }
      return <DeviceInfoCard title={title} cards={cardItems} />

    case 4:
      const devStatusCardValues = fetchCardValues(
        paramNames,
        paramValues,
        'Device',
        'Status'
      )
      return <DeviceStatusCard title={title} cards={devStatusCardValues} />

    case 5:
      return <ToolsCard title={title} />

    case 0:
      const malwareCardValues = fetchCardValues(
        paramNames,
        paramValues,
        'IOT-Security',
        'ExploitsMalware'
      )
      return (
        <ExploitsMalwareCard
          title={title}
          description={description}
          cards={malwareCardValues}
        />
      )

    case 1:
      const vulnerabilityCardValues = fetchCardValues(
        paramNames,
        paramValues,
        'IOT-Security',
        'Vulnerability'
      )
      return (
        <VulnerabilityCard
          title={title}
          description={description}
          cards={vulnerabilityCardValues}
        />
      )

    case 2:
      const contextCardValues = fetchCardValues(
        paramNames,
        paramValues,
        'IOT-Security',
        'Context'
      )
      return (
        <ContextCard
          title={title}
          description={description}
          cards={contextCardValues}
        />
      )

    case 6:
      return <DeviceHistoryCard title={title} />

    case 7:
      var cardItems = []
      // ./DevInfo
      if (paramNames && paramNames[0] && paramValues) {
        const deviceInfoNode = paramNames[0].children.filter(
          (param) => param.name === 'DevInfo'
        )

        if (deviceInfoNode && deviceInfoNode[0].children) {
          deviceInfoNode[0].children.forEach((pname) => {
            const foundParam = paramValues.filter(
              (cpeParameter) => cpeParameter.val().param_id === pname.id
            )
            if (foundParam[0] && foundParam[0].val()) {
              cardItems.push({
                name: pname.name,
                value: foundParam[0].val().value,
              })
            }
          })
        }

        const deviceNode = paramNames[0].children.filter(
          (param) => param.name === 'Device'
        )

        if (deviceNode[0] && deviceNode[0].children) {
          const deviceStatusNode = deviceNode[0].children.filter(
            (param) => param.name === 'Status'
          )
          if (deviceStatusNode[0] && deviceStatusNode[0].children) {
            const signalNodes = deviceStatusNode[0].children.filter(
              (node) => node.name === 'SignalStrength'
            )
            if (signalNodes) {
              signalNodes.forEach((nodeItem) => {
                const foundParam = paramValues.filter(
                  (cpeParameter) => cpeParameter.val().param_id === nodeItem.id
                )
                if (foundParam[0] && foundParam[0].val()) {
                  cardItems.push({
                    name: nodeItem.name,
                    value: foundParam[0].val().value,
                  })
                }
              })
            }
          }
        }
      }
      return <ConnectivityCard title={title} cards={cardItems} />

    case 8:
      var cardItems = []
      if (paramNames[0] && paramNames[0].children) {
        const deviceNode = paramNames[0].children.filter(
          (param) => param.name === 'Device'
        )

        if (deviceNode[0] && deviceNode[0].children) {
          const deviceStatusNode = deviceNode[0].children.filter(
            (param) => param.name === 'Storage'
          )
          if (deviceStatusNode[0] && deviceStatusNode[0].children) {
            deviceStatusNode[0].children.forEach((parentNodeItem) => {
              if (parentNodeItem.children) {
                parentNodeItem.children.forEach((nodeItem) => {
                  const foundParam = paramValues.filter(
                    (cpeParameter) =>
                      cpeParameter.val().param_id === nodeItem.id
                  )
                  if (foundParam[0] && foundParam[0].val()) {
                    cardItems.push({
                      name: parentNodeItem.name + '/' + nodeItem.name,
                      value: foundParam[0].val().value,
                    })
                  }
                })
              }
            })
          }
        }
      }

      return (
        <StorageCard
          title={title}
          description={description}
          cards={cardItems}
        />
      )

    default:
      return <DefaultCard title={title} />
  }
}

function DeviceMainTab({ cpeParameterNames, cpeParameterValues }) {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {cards.map((card, index) => (
        <Grid item xs={2} sm={4} md={4} key={index}>
          {/* Card */}
          {renderSwitch(
            card.id,
            card.title,
            card.description,
            cpeParameterNames,
            cpeParameterValues
          )}
          {/* End Card */}
        </Grid>
      ))}
    </Grid>
  )
}

export default DeviceMainTab
