import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import SdStorageIcon from '@mui/icons-material/SdStorage'
import SdIcon from '@mui/icons-material/Sd'
import StorageIcon from '@mui/icons-material/Storage'
import Stack from '@mui/material/Stack'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const SmallTextComponent = styled('span')({
  color: 'black',
  fontSize: 14,
})

function StorageCard({ title, description, cards }) {
  function getCardValue(cardName) {
    const ci = cards.filter((item) => item.name == cardName)
    if (ci && ci[0]) {
      return ci[0].value
    }
    return ''
  }

  // {name: 'DeviceMemory/AvailableSpace', value: '348.00 MB'}
  // {name: 'DeviceMemory/TotalSpace', value: '826.00 MB'}
  // {name: 'InternalCard/AvailableSpace', value: '3275.16 MB'}
  // {name: 'InternalCard/TotalSpace', value: '11622.65 MB'}
  // {name: 'SdCard/AvailableSpace', value: '685.12 MB'}
  // {name: 'SdCard/TotalSpace', value: '1200.00 MB'}

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            S
          </Avatar>
        }
        title={title}
        subheader={description}
      />
      <CardContent sx={{ minHeight: 275 }}>
        <Table sx={{ minWidth: 250 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{ width: 150 }}>
                Device Memory
              </StyledTableCell>
              <StyledTableCell align="left">
                <SmallTextComponent>
                  {getCardValue('DeviceMemory/AvailableSpace')} /{' '}
                  {getCardValue('DeviceMemory/TotalSpace')}
                </SmallTextComponent>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Internal Card
              </StyledTableCell>
              <StyledTableCell align="left">
                <SmallTextComponent>
                  {getCardValue('InternalCard/AvailableSpace')} /{' '}
                  {getCardValue('InternalCard/TotalSpace')}
                </SmallTextComponent>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Sd Card
              </StyledTableCell>
              <StyledTableCell align="left">
                <SmallTextComponent>
                  {getCardValue('SdCard/AvailableSpace')} /{' '}
                  {getCardValue('SdCard/TotalSpace')}
                </SmallTextComponent>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
          <StorageIcon sx={{ fontSize: 57 }} color="primary" />
          <SdStorageIcon sx={{ fontSize: 57 }} color="primary" />
          <SdIcon sx={{ fontSize: 50 }} color="primary" />
        </Stack>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="refresh status">
          <SyncIcon color="primary" />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default StorageCard
