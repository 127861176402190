import React from 'react'
import Devicetable from '../../components/deviceTable/Devicetable'
import { ref, child, get } from 'firebase/database'
import { useEffect } from 'react'
import { setGlobalState } from '../../state'
import { database } from '../../firebase'
import './home.scss'

const Home = () => {
  useEffect(() => {
    const fetchData = async () => {
      let list = []

      const dbRef = ref(database)
      get(child(dbRef, `cpe_parameter_name`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.val().forEach((doc) => {
              list.push({
                ...doc,
              })
            })

            let result = []
            let level = { result }

            // console.log('COUNT LIST: ' + list.length)

            const sortedNodeTree = list.sort((a, b) =>
              a.full_name > b.full_name ? 1 : b.full_name > a.full_name ? -1 : 0
            )

            sortedNodeTree.forEach((paramName) => {
              const itemSplitArray = paramName.full_name.split('/')
              itemSplitArray.reduce((r, name, i, a) => {
                if (!r[name]) {
                  r[name] = { result: [] }
                  if (i === itemSplitArray.length - 1) {
                    r.result.push({
                      name,
                      full_name: paramName.full_name,
                      format: paramName.format,
                      id: paramName.id,
                      children: r[name].result,
                    })
                  } else {
                    r.result.push({
                      name,
                      children: r[name].result,
                    })
                  }
                }

                return r[name]
              }, level)
            })

            //take frist array elements named '.'
            const treeRootObject = result.filter((r) => r.name === '.')
            setGlobalState('cpe_parameter_name', treeRootObject)
            window.sessionStorage.setItem(
              'reducer',
              JSON.stringify(treeRootObject)
            )
            // console.log(JSON.stringify(treeRootObject))

            // console.log('Param names completed!')

            //end
          } else {
            console.log('No data available')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    fetchData()
  }, [])

  return <Devicetable />
}

export default Home
