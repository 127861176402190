import { Outlet } from 'react-router-dom'
import { deepPurple } from '@mui/material/colors'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useState } from 'react'
import Navbar2 from '../components/navbar/Navbar2'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import './test-layout.scss'
import { useGlobalState } from '../state'
import Stack from '@mui/material/Stack'

const initialManufStateSaved = window.sessionStorage.getItem(
  'reducer_manufacturer'
)
const initialModelStateSaved = window.sessionStorage.getItem('reducer_model')

const TestLayout = () => {
  const [navVisible, showNavbar] = useState(true)
  const params = useParams()

  var [manufacturerParam] = useGlobalState('manufacturer')
  if (!manufacturerParam.length && initialManufStateSaved) {
    manufacturerParam = initialManufStateSaved
  }

  var [modelParam] = useGlobalState('model')
  if (!modelParam.length && initialModelStateSaved) {
    modelParam = initialModelStateSaved
  }

  return (
    <div className="App">
      <Navbar2 visible={navVisible} show={showNavbar} />
      <div className={!navVisible ? 'page' : 'page page-with-navbar'}>
        <header className="header">
          <div className="header-nav-wrap">
            <div className="logo">
              <Link className="logo" to="/">
                Spartan<span>Shield</span>
              </Link>
            </div>
            {/* device info */}
            <div className="device-info box">{/* no device info */}</div>

            {/* user info */}
            <div className="user-info box">
              <div className="ava">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar className="avatar" sx={{ bgcolor: deepPurple[500] }}>
                    T
                  </Avatar>
                  <Stack
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <span className="username">test@test.com</span>
                    <span>Administrator</span>
                  </Stack>
                </Stack>
              </div>
              {/* <div className="sc-dsQDmV faemxx">
            <AccessTimeOutlinedIcon className="icon" />
            {date.toLocaleTimeString()}
          </div> */}
            </div>
          </div>
        </header>

        {/* Body */}
        <div className="container-wrapper">
          <div className="container">
            <Outlet />
          </div>
        </div>
        {/* Footer */}
        <footer className="footer">
          <div className="copy">
            <img
              className="logo-m"
              src="/images/spartan-shield-logo-m.png"
              alt="Spartan Shield"
            />
            <span>SpartanShield Technologies 2023</span>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default TestLayout
