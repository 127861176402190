import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80'
import Battery20Icon from '@mui/icons-material/Battery20'
import HomeIcon from '@mui/icons-material/Home'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function DeviceStatusCard({ title, cards }) {
  function getCardValue(cardName) {
    const ci = cards.filter((item) => item.name == cardName)
    if (ci && ci[0]) {
      return ci[0].value
    }
    return ''
  }

  // {name: 'BatteryLevel', value: '100.0%'}
  // {name: 'BatteryStatus', value: 'Charging'}
  // {name: 'IMEI', value: '11cb2ce2c10b44e0'}
  // {name: 'IPAddress', value: '192.168.8.172'}
  // {name: 'MobileNetworkType', value: 'HSDPA'}
  // {name: 'Network', value: 'Vodafone UA'}
  // {name: 'PhoneNumber', value: ''}
  // {name: 'Roaming', value: 'Not roaming'}
  // {name: 'ServiceState', value: 'In service'}
  // {name: 'SignalStrength', value: '-97 dBm 8 asu'}
  // {name: 'Uptime', value: '03:01:26'}
  // {name: 'WifiMACAddress', value: '02:00:00:00:00:00'}

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            DS
          </Avatar>
        }
        title={title}
        subheader="Device state"
      />
      <CardContent sx={{ minHeight: 295 }}>
        <Table sx={{ minWidth: 250 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" width={150}>
                IMEI
              </StyledTableCell>
              <StyledTableCell align="left">
                {getCardValue('IMEI')}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Roaming
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="row" spacing={1}>
                  <HomeIcon fontSize="small" color="primary" />
                  {getCardValue('Roaming')}
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Service state
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="row" spacing={2}>
                  <CheckCircleIcon fontSize="small" color="success" />
                  {getCardValue('ServiceState')}
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Battery level
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="row" spacing={1}>
                  <Battery20Icon fontSize="small" color="warning" />
                  {getCardValue('BatteryLevel')}
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Battery status
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="row" spacing={1}>
                  <BatteryCharging80Icon fontSize="small" />
                  {getCardValue('BatteryStatus')}
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Uptime
              </StyledTableCell>
              <StyledTableCell align="left">
                <a href="#">{getCardValue('Uptime')}</a>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="refresh status">
          <SyncIcon color="primary" />
        </IconButton>
      </CardActions>
    </Card>
  )
}
{
  /* <HomeIcon fontSize="small" /> */
}
export default DeviceStatusCard
