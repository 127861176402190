import './usertable.scss'
import { DataGrid } from '@mui/x-data-grid'
import { userColumns } from '../../datatablesource'
import { ref, child, get } from 'firebase/database'
import { useEffect, useState } from 'react'
import { database } from '../../firebase'

const Usertable = () => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let list = []

      const dbRef = ref(database)
      get(child(dbRef, `users`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.val().forEach((doc) => {
              list.push(doc)
            })

            // console.log(list)
            setUsers(list)
          } else {
            console.log('No data available')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    fetchData()
  }, [])

  return (
    <div className="usertable">
      <DataGrid
        rows={users}
        columns={userColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
      />
    </div>
  )
}

export default Usertable
