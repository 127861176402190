// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  //   apiKey: process.env.REACT_APP_FIREBASE_KEY,
  apiKey: "AIzaSyDbiBB1tpAtmJdHdnNLzAHCIG4gMKuMOu0",
  authDomain: "spartan-38cb3.firebaseapp.com",
  projectId: "spartan-38cb3",
  storageBucket: "spartan-38cb3.appspot.com",
  messagingSenderId: "218606277130",
  appId: "1:218606277130:web:f88ec8c4771c0fab22c5f3",
  // The value of `databaseURL` depends on the location of the database
  databaseURL: "wss://spartan-38cb3-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);