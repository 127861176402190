import './notfound.scss'

const GenericNotFound = () => {
  return (
    <>
      <header className="sc-jIAOiI haFYRS">
        <div className="sc-ZyCDH ARXcN">
          <a className="sc-idiyUo Vxrme sc-TRNrF kiQvRe" href="/">
            Spartan<span>Shield</span>
          </a>
        </div>
      </header>
      <div class="sc-dUWWNf dHbgqB">
        <div class="sc-bczRLJ dDRJpc sc-hKMtZM jtrHBG container">
          <div class="sc-hWlEnr kMPuRP">
            <div class="sc-kKrQFk ccVxvL">
              <img src="/images/not-found.png" alt="404" />
            </div>
            <h1 class="sc-hTtIkV isTfLR">404 Page Not Found</h1>
            <h5 class="sc-fJExmy iKHUqQ">
              Oopps. The page you were looking for doesn't exist.
            </h5>
            <p class="sc-cSGoSj jnIkmC">
              You may have mistyped the address or the page may have moved. Try
              searching below.
            </p>
            <div class="sc-liaBrn MUbdA">
              <input
                type="text"
                class="sc-iNWwEs iLQNEh form-control"
                id="search"
                name="search"
                placeholder="Search"
                value=""
              />
              <button type="submit" class="sc-fnykZs iSblIP btn">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <footer className="sc-jQHtVU igAodF">
        <div className="sc-fvNpTx eeTpIf">
          <img
            className="logo-m"
            src="/images/spartan-shield-logo-m.png"
            alt="Spartan Shield"
          />
          <span>SpartanShield Technologies 2023</span>
        </div>
      </footer>
    </>
  )
}

export default GenericNotFound
