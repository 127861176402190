import { useContext, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/home/Home'
import Device from './pages/device/Device'
import Login from './pages/login/Login'
import Users from './pages/users/Users'
import { AuthContext } from './context/AuthContext'
import MainLayout from './layouts/MainLayout'
import DeviceLayout from './layouts/DeviceLayout'
import GenericNotFound from './pages/notFound/GenericNotFound'
import Forgot from './pages/forgot/Forgot'
import TestLayout from './layouts/TestLayout'

function App() {
  const { currentUser } = useContext(AuthContext)

  const RequiredAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />
  }

  // console.log(currentUser)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<DeviceLayout />}>
          <Route
            index
            element={
              <RequiredAuth>
                <Home />
              </RequiredAuth>
            }
          />
          <Route
            path="users"
            element={
              <RequiredAuth>
                <Users />
              </RequiredAuth>
            }
          />
          <Route
            path="devices"
            element={
              <RequiredAuth>
                <Home />
              </RequiredAuth>
            }
          />
        </Route>
        <Route path="/devices" element={<DeviceLayout />}>
          <Route
            path=":id"
            element={
              <RequiredAuth>
                <Device />
              </RequiredAuth>
            }
          />
        </Route>

        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="*" exact={true} element={<GenericNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App

// npm install react-icons --save
// https://react-icons.github.io/react-icons/icons?name=ri
// https://www.npmjs.com/package/uuid
