import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { useState } from 'react'
import MenuButtons from './MenuButtons'
import { Divider } from '@mui/material'
import './paramsDeviceTree.scss'

// =====================
// Product_class_group таблица в парсинге дерева не учавствует.
// Учавствуют таблицы :
// 1. Cpe - основная таблица по дивайсу
// 2. cpe_parameter - основная таблица по параметру включающая значение параметра
// 3. cpe_parameter_name - имя параметра включая полный путь к нему в дереве.  "node" - это узел который содержит остальные узлы
// ( или параметры ), "chr" - конечный параметр.
// Алгоритм построения дерева :
// 1. Из cpe_param_Name вытаскиваешь все строки у которых "node" и full_name содержит только один "/" после точки ( это все узлы 1го уровня ).
// 2. Для каждого из узлов 1го уровня строишь его поддерево ( берутся все параметры с "node" , "chr" у которых в full_name входит имя
// родительского узла ). Последний элемент поддерева с типом "chr" это и есть конечный параметр. Для него
// берется сразу значение для отображения имени параметра и его значения.
// Все просто, ничего сложного :)
// В последствие изменим построение дерева чтобы клиент напрямую не лез в базу а получил дерево параметров в виде json для заданного device_id.

function ParamsDeviceTree({ tree, node, selectNode }) {
  const [checklist, setChecklist] = useState([])

  // console.log(tree)

  const isOpened = (nodeId) => {
    let flag = false
    const item = checklist.filter((ch) => ch.id === nodeId)
    if (item.length > 0) {
      flag = !item[0].collapsed
    }
    return flag
  }

  function isChildNodes(subTree) {
    let result = false
    if (subTree.length > 0) {
      subTree.forEach((s) => {
        if (s.format === 'node') {
          result = true
        }
      })
    }
    return result
  }

  const countDown = (mtree, isInset, level) => {
    return (
      <>
        {mtree.map((todo, index) => (
          <div key={index}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  //todo: Render param values
                  // console.log(todo)
                  selectNode({
                    ...node,
                    id: todo.id,
                    full_name: todo.full_name,
                    format: todo.format,
                    children: todo.children,
                  })
                }}
              >
                {isInset ? (
                  <ListItemText
                    className={`level${level}`}
                    primary={todo.name}
                  />
                ) : (
                  <ListItemText primary={todo.name} />
                )}
              </ListItemButton>

              {!!isChildNodes(todo.children) && (
                <MenuButtons
                  nodeId={todo.id}
                  list={checklist}
                  setList={setChecklist}
                />
              )}
            </ListItem>

            {!!isChildNodes(todo.children) && (
              <Collapse in={isOpened(todo.id)} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                  {/* <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText inset primary="Starred" />
                  </ListItemButton> */}
                  {countDown(
                    todo.children !== undefined
                      ? todo.children.filter((n) => n.format === 'node')
                      : [],
                    true,
                    level + 1
                  )}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </>
    )
  }

  // console.log('TREE:')
  // console.log(tree)

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {countDown(
        tree[0].children
          ? tree[0].children.filter(
              (item) =>
                item.format === 'node' &&
                item.children &&
                item.children.length > 0
            )
          : [],
        false,
        1
      )}
    </List>

    // <List
    //   sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
    //   component="nav"
    //   aria-labelledby="nested-list-subheader"
    // >
    //   <ListItemButton>
    //     <ListItemIcon>
    //       <SendIcon />
    //     </ListItemIcon>
    //     <ListItemText primary="Sent mail" />
    //   </ListItemButton>
    //   <ListItemButton>
    //     <ListItemIcon>
    //       <DraftsIcon />
    //     </ListItemIcon>
    //     <ListItemText primary="Drafts" />
    //   </ListItemButton>
    //   <ListItemButton onClick={handleClick}>
    //     <ListItemIcon>
    //       <InboxIcon />
    //     </ListItemIcon>
    //     <ListItemText primary="Inbox" />
    //     {open ? <ExpandLess /> : <ExpandMore />}
    //   </ListItemButton>
    //   <Collapse in={open} timeout="auto" unmountOnExit>
    //     <List component="div" disablePadding>
    //       <ListItemButton sx={{ pl: 4 }}>
    //         <ListItemIcon>
    //           <StarBorder />
    //         </ListItemIcon>
    //         <ListItemText primary="Starred" />
    //       </ListItemButton>
    //     </List>
    //   </Collapse>
    // </List>
  )
}

export default ParamsDeviceTree
