// Manufacturer, Model name, IMEI, OS Name, Device type, IP Address, OS Version, Created, Updated

// created:"2015-06-18 16:42:37"
// id:1
// manufacturer:"samsung"
// model:"GT-I9300"
// updated:"2015-06-18 16:42:37"

export const userColumns = [
  { field: 'id', headerName: 'ID', width: 60 },
  {
    field: 'name',
    headerName: 'Name',
    width: 160,
  },
  {
    field: 'fullname',
    headerName: 'Full name',
    width: 180,
  },

  {
    field: 'password',
    headerName: 'Password',
    width: 180,
  },
  {
    field: 'permission',
    headerName: 'Permission',
    width: 120,
  },
  {
    field: 'regdate',
    headerName: 'Registration',
    width: 180,
  },
]

export const deviceColumns = [
  { field: 'id', headerName: 'ID', width: 60 },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    width: 120,
  },
  {
    field: 'model',
    headerName: 'Model name',
    width: 180,
  },

  // {
  //   field: "imei",
  //   headerName: "IMEI",
  //   width: 100,
  // },
  {
    field: 'osname',
    headerName: 'OS Name',
    width: 100,
  },
  {
    field: 'devicetype',
    headerName: 'Device type',
    width: 100,
  },
  {
    field: 'ipaddress',
    headerName: 'IP Address',
    width: 100,
  },

  {
    field: 'created',
    headerName: 'Created',
    width: 180,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
  },
  // {
  //   field: "user",
  //   headerName: "User",
  //   width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">
  //         <img className="cellImg" src={params.row.img} alt="avatar" />
  //         {params.row.username}
  //       </div>
  //     );
  //   },
  // },
  // {
  //   field: "email",
  //   headerName: "Email",
  //   width: 230,
  // },

  // {
  //   field: "age",
  //   headerName: "Age",
  //   width: 100,
  // },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
]

//temporary data
export const userRows = [
  {
    id: 1,
    username: 'Snow',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    status: 'active',
    email: '1snow@gmail.com',
    age: 35,
  },
  {
    id: 2,
    username: 'Jamie Lannister',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '2snow@gmail.com',
    status: 'passive',
    age: 42,
  },
  {
    id: 3,
    username: 'Lannister',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '3snow@gmail.com',
    status: 'pending',
    age: 45,
  },
  {
    id: 4,
    username: 'Stark',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '4snow@gmail.com',
    status: 'active',
    age: 16,
  },
  {
    id: 5,
    username: 'Targaryen',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '5snow@gmail.com',
    status: 'passive',
    age: 22,
  },
  {
    id: 6,
    username: 'Melisandre',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '6snow@gmail.com',
    status: 'active',
    age: 15,
  },
  {
    id: 7,
    username: 'Clifford',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '7snow@gmail.com',
    status: 'passive',
    age: 44,
  },
  {
    id: 8,
    username: 'Frances',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: '8snow@gmail.com',
    status: 'active',
    age: 36,
  },
  {
    id: 9,
    username: 'Roxie',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'snow@gmail.com',
    status: 'pending',
    age: 65,
  },
  {
    id: 10,
    username: 'Roxie',
    img: 'https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'snow@gmail.com',
    status: 'active',
    age: 65,
  },
]
