import RemoveIcon from '@mui/icons-material/Remove'

function ButtonCollapse({ nodeId, list, setList }) {
  return (
    <RemoveIcon
      onClick={() => {
        const foundCheck = list.filter((check) => check.id === nodeId)

        if (foundCheck.length > 0) {
          const newState = list.map((obj) => {
            if (obj.id === nodeId) {
              // console.log(!obj.checked)
              return { ...obj, collapsed: !obj.collapsed }
            }
            // otherwise return object as is
            return obj
          })
          setList(newState)
        } else {
          const newCheckItem = { id: nodeId, collapsed: true }
          list.push(newCheckItem)
          setList(list)
        }
      }}
    />
  )
}

export default ButtonCollapse
