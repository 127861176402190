import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
// sub components
import ParamsDeviceTree from '../../components/paramsTree/ParamsDeviceTree'
import ParamsDeviceTable from '../../components/paramsTable/ParamsDeviceTable'
import { useState } from 'react'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

function DeviceSettingsTab({ tree, cpeId }) {
  // const params = useParams()

  // const [selectedNode, setSelectedNode] = useState({ id: 0, full_name: '' })
  const [selectedNode, setSelectedNode] = useState({
    id: 0,
    full_name: '',
    format: '',
    children: [],
  })
  // const [cpeId, setCpeId] = useState(-1);

  // console.log('Device settings tab')
  // console.log(tree)

  // // get latest cpe row, by product_class_group.id == cpe.group_id
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const dbRef = ref(database, 'cpe');

  //     const mostViewedPosts = query(dbRef,
  //       orderByChild('group_id'),
  //       equalTo(Number(params.id)),
  //       limitToLast(1));

  //     onValue(mostViewedPosts, (snapshot) => {
  //       snapshot.forEach((childSnapshot) => {
  //         const childData = childSnapshot.val();
  //         console.log("CPE ID fetched: " + childData.id);
  //         setCpeId(Number(childData.id))
  //         // ...
  //       });
  //     });
  //   }
  //   fetchData()
  // }, []);

  // function Login() {
  //   const [data, setData] = useState({ username: '', password: '' })

  //   function handleFromSubmit(event) {
  //     event.preventDefault()

  //     console.log(data)
  //     alert(JSON.stringify(data))
  //   }

  //   function handleInputChange(e, name) {
  //     setData({ ...data, [name]: e.target.value })

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Item>
              <ParamsDeviceTree
                tree={tree}
                node={selectedNode}
                selectNode={setSelectedNode}
              />
            </Item>
          </Grid>
          <Grid item xs={8} className="params_device_table">
            <ParamsDeviceTable node={selectedNode} cpeId={cpeId} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default DeviceSettingsTab
