import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import { Component } from 'react'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import '../card.scss'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

class ExploitsMalwareCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updatedList: [],
    }
  }

  getCardValue(cardName) {
    const ci = this.props.cards.filter((item) => item.name == cardName)
    if (ci && ci[0]) {
      return ci[0].value
    }
    return ''
  }

  isAlertDetected(cardName) {
    return this.state.updatedList.find((item) => item.name === cardName)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.cards.length > 0 &&
      prevProps.cards.length === this.props.cards.length
    ) {
      console.log('Before for cycle')
      for (let i = 0; i < this.props.cards.length; i++) {
        console.log('card ', this.props.cards[i].value)
        console.log('prev card ', prevProps.cards[i].value)
        if (this.props.cards[i].value !== prevProps.cards[i].value) {
          // console.log('NEW Value found: ', this.props.cards[i].value)
          this.setState((prevState) => ({
            updatedList: [...prevState.updatedList, this.props.cards[i]],
          }))
          console.log(this.state.updatedList)
        }
      }
    }
  }

  render() {
    return (
      <Card sx={{ minWidth: 275, minHeight: 526 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              EM
            </Avatar>
          }
          title={this.props.title}
          subheader={this.props.description}
        />
        <CardContent sx={{ minHeight: 470 }}>
          <Table sx={{ minWidth: 250 }} aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" width={190}>
                  Abnormal connections between IoTs
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    this.isAlertDetected('AbnormalConnectionsBetweenIoTs')
                      ? 'element-visible'
                      : 'element-hidden'
                  }
                >
                  <WarningAmberOutlinedIcon className="warning-icon" />
                  {this.getCardValue('AbnormalConnectionsBetweenIoTs')}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" width={150}>
                  Malicious files on devices
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    this.isAlertDetected('MaliciousFilesOnDevices')
                      ? 'element-visible'
                      : 'element-hidden'
                  }
                >
                  <WarningAmberOutlinedIcon className="warning-icon" />
                  {this.getCardValue('MaliciousFilesOnDevices')}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" width={150}>
                  Connections to risky websites
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    this.isAlertDetected('ConnectionsToRiskyWebsites')
                      ? 'element-visible'
                      : 'element-hidden'
                  }
                >
                  <WarningAmberOutlinedIcon className="warning-icon" />
                  {this.getCardValue('ConnectionsToRiskyWebsites')}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" width={150}>
                  Abnormal traffic between devices
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    this.isAlertDetected('AbnormalTrafficBetweenDevices')
                      ? 'element-visible'
                      : 'element-hidden'
                  }
                >
                  <WarningAmberOutlinedIcon className="warning-icon" />
                  {this.getCardValue('AbnormalTrafficBetweenDevices')}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" width={150}>
                  Personal device connecting to a large number of devices
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    this.isAlertDetected(
                      'PersonalDeviceConnectingLargeNumberDev'
                    )
                      ? 'element-visible'
                      : 'element-hidden'
                  }
                >
                  <WarningAmberOutlinedIcon className="warning-icon" />
                  {this.getCardValue('PersonalDeviceConnectingLargeNumberDev')}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
  }
}

export default ExploitsMalwareCard
