import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function DeviceInfoCard({ title, cards }) {
  function getCardValue(cardName) {
    const ci = cards.filter((item) => item.name == cardName)
    if (ci && ci[0]) {
      return ci[0].value
    }
    return ''
  }

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              DS
            </Avatar>
          }
          title={title}
          subheader="Software and model info"
        />

        {cards.length ? (
          <CardContent>
            <Table sx={{ minWidth: 250 }} aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" width={150}>
                    OS Name
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getCardValue('OSName')}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    OS Version
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getCardValue('Version')}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    Baseband version
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getCardValue('BasebandVersion')}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    Build date
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getCardValue('BuildDate')}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    Kernel version
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getCardValue('KernelVersion')}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    Model number
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getCardValue('ModelNumber')}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </CardContent>
        ) : (
          <Stack spacing={1} sx={{ mt: 2, mr: 2, ml: 2 }}>
            <Skeleton variant="rounded" height={36} />
            <Skeleton variant="rounded" height={36} />
            <Skeleton variant="rounded" height={37} />
            <Skeleton variant="rounded" height={37} />
            <Skeleton variant="rounded" height={37} />
            <Skeleton variant="rounded" height={37} />
          </Stack>
        )}

        <CardActions disableSpacing>
          <IconButton aria-label="refresh status">
            <SyncIcon color="primary" />
          </IconButton>
        </CardActions>
      </Card>
    </>
  )
}

export default DeviceInfoCard
