import React from 'react'
import {
  FaAngleRight,
  FaAngleLeft,
  FaChartBar,
  FaThLarge,
  FaCog,
  FaSignOutAlt,
  FaBars,
  FaUsers,
} from 'react-icons/fa'
import { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import './navbar2.scss'

const ICON_SIZE = 20

function Navbar({ visible, show }) {
  const { dispatch } = useContext(AuthContext)

  return (
    <>
      <div className="mobile-nav">
        <button className="mobile-nav-btn" onClick={() => show(!visible)}>
          <FaBars size={24} />
        </button>
      </div>
      <nav className={!visible ? 'navbar navigation' : 'navigation'}>
        <button
          type="button"
          className="nav-btn"
          onClick={() => show(!visible)}
        >
          {!visible ? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
        </button>
        <div>
          <div className="links nav-top">
            <Link
              to="/"
              style={{ textDecoration: 'none' }}
              className="nav-link"
            >
              <FaThLarge size={ICON_SIZE} />
              <span>Dashboard</span>
            </Link>
            <Link
              to="/users"
              className="nav-link"
              style={{ textDecoration: 'none' }}
            >
              <FaUsers size={ICON_SIZE} />
              <span>Users</span>
            </Link>
            <NavLink to="/logs" className="nav-link">
              <FaChartBar size={ICON_SIZE} />
              <span>Logs</span>
            </NavLink>
          </div>
        </div>

        <div className="links">
          <NavLink to="/settings" className="nav-link">
            <FaCog size={ICON_SIZE} />
            <span>Settings</span>
          </NavLink>
          <div
            className="nav-link"
            onClick={() => dispatch({ type: 'LOGOUT' })}
          >
            <FaSignOutAlt size={ICON_SIZE} />
            <span>Logout</span>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
