import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import './login.scss'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase'
import { AuthContext } from '../../context/AuthContext'

const Login = () => {
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const { dispatch } = useContext(AuthContext)

  const handleLogin = (e) => {
    e.preventDefault()

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // console.log(user)
        dispatch({ type: 'LOGIN', payload: user })
        navigate('/')
      })
      .catch((error) => {
        console.log(error.code)
        console.log(error.message)
        setError(true)
      })
  }

  return (
    <>
      <header className="sc-jIAOiI haFYRS">
        <div className="sc-ZyCDH ARXcN">
          <a className="sc-idiyUo Vxrme sc-TRNrF kiQvRe" href="/">
            Spartan<span>Shield</span>
          </a>
        </div>
      </header>
      <div className="sc-dUWWNf dHbgqB">
        <div className="sc-bczRLJ dDRJpc sc-hKMtZM jtrHBG container">
          <div className="sc-jIZahH fPTPwa sc-bwANAz chigLQ media">
            <div className="sc-gXmSlM eSRnOk sc-ekGZSs tBulW media-body">
              <div className="sc-btIRyd dxUnis">
                <img src="/images/login-promo.png" alt="Login" />
              </div>
              <div className="sc-kNjMHG dmqboF">
                Device management admin dashboard
              </div>
            </div>

            <div className="sc-hWlEnr cewXZL">
              <div className="mobile-img">
                <img src="/images/login-promo.png" alt="Login" />
              </div>
              <div className="sc-hbjaKc gtIlHT">
                <h3 className="sc-cgFpzT eRlVrJ">Sign In</h3>
                <p className="sc-jDDxOa ddCqhf">
                  Welcome back! Please signin to continue.
                </p>

                <form onSubmit={handleLogin}>
                  <div className="sc-knuRna vrcaG form-group">
                    <label for="email" className="sc-kTGBUR cHOCRe label">
                      Email address
                    </label>

                    <input
                      type="email"
                      className="sc-iNWwEs kGMEvx form-control"
                      id="email"
                      name="email"
                      placeholder="yourname@yourmail.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="sc-knuRna vrcaG form-group">
                    <div className="sc-bKhNmF cnjEWl">
                      <label for="password" className="sc-kTGBUR cHOCRe label">
                        Password
                      </label>
                      <a
                        rel="preload"
                        className="sc-idiyUo kscDRi"
                        href="/forgot-password"
                      >
                        Forgot password?
                      </a>
                    </div>

                    <input
                      type="password"
                      className="sc-iNWwEs kGMEvx form-control"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <button type="submit" className="sc-fnykZs iaBmoU btn">
                    Sign In
                  </button>
                  {error && (
                    <div className="error">Wrong email or password!</div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="sc-jQHtVU igAodF">
        <div className="sc-fvNpTx eeTpIf">
          <img
            className="logo-m"
            src="/images/spartan-shield-logo-m.png"
            alt="Spartan Shield"
          />
          <span>SpartanShield Technologies 2023</span>
        </div>
      </footer>
    </>
  )
}

export default Login
