import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar'
import Stack from '@mui/material/Stack'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function ConnectivityCard({ title, cards }) {
  function getCardValue(cardName) {
    const ci = cards.filter((item) => item.name == cardName)
    if (ci && ci[0]) {
      return ci[0].value
    }
    return ''
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            C
          </Avatar>
        }
        title={title}
        subheader="Connective quality, state or capability"
      />
      <CardContent sx={{ minHeight: 275 }}>
        <Table sx={{ minWidth: 250 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" width={150}>
                Dev Id
              </StyledTableCell>
              <StyledTableCell align="left">
                {getCardValue('DevId')}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Bearer
              </StyledTableCell>
              <StyledTableCell align="left">
                {getCardValue('Bearer')}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Signal strength
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="row" spacing={1}>
                  <SignalCellular1BarIcon fontSize="small" color="warning" />
                  <b>{getCardValue('SignalStrength')}</b>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                SMCC
              </StyledTableCell>
              <StyledTableCell align="left">42</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                SNCC
              </StyledTableCell>
              <StyledTableCell align="left">31</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="refresh status">
          <SyncIcon color="primary" />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default ConnectivityCard
