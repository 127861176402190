const cards = [
  {
    id: 0,
    title: 'Exploits malware',
    description: 'Exploit is a type of malware',
    icon: 'RiRouterLine',
  },
  {
    id: 1,
    title: 'Vulnerability',
    description: 'Weakness, flaw, or error found within a system',
    icon: 'RiRouterLine',
  },
  {
    id: 2,
    title: 'Context',
    description: 'A security context defines access control',
    icon: 'RiRouterLine',
  },
  {
    id: 3,
    title: 'Device information',
    icon: 'RiRouterLine',
  },
  {
    id: 4,
    title: 'Device status',
    icon: 'RiRouterLine',
  },
  {
    id: 5,
    title: 'Tools',
    icon: 'RiRouterLine',
  },
  {
    id: 6,
    title: 'Device history',
    icon: 'RiRouterLine',
  },

  {
    id: 7,
    title: 'Connectivity',
    icon: 'RiRouterLine',
  },
  {
    id: 8,
    title: 'Storage',
    description: 'Device memory, internal card and SD storage',
    icon: 'RiRouterLine',
  },
]

export default cards
