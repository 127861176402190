import React from 'react'
import ButtonCollapse from './ButtonCollapse'
import ButtonExpand from './ButtonExpand'

const MenuButtons = ({ nodeId, list, setList }) => {
  const isCollapsed = () => {
    let flag = true
    const item = list.filter((ch) => ch.id === nodeId)
    // console.log(checklist)
    if (item.length > 0) {
      flag = item[0].collapsed
    } else {
      const newCheckItem = { id: nodeId, collapsed: flag }
      list.push(newCheckItem)
      setList(list)
    }
    // console.log(flag)
    return flag
  }

  return (
    <>
      {isCollapsed() ? (
        <ButtonExpand
          nodeId={nodeId}
          list={list}
          setList={setList}
        ></ButtonExpand>
      ) : (
        <ButtonCollapse
          nodeId={nodeId}
          list={list}
          setList={setList}
        ></ButtonCollapse>
      )}
    </>
  )
}

export default MenuButtons
