import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'

import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'

import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

function DeviceHistoryCard({ title }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            DH
          </Avatar>
        }
        title={title}
        subheader="Show device log by date"
      />
      <CardContent sx={{ minHeight: 275 }}>
        <Stack direction="row" spacing={1}>
          <TextField
            disabled
            id="outlined-disabled"
            label="Disabled"
            defaultValue="18/03/2023"
          />
          <Button aria-label="refresh status">
            <CalendarMonthIcon color="primary" />
          </Button>
        </Stack>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="refresh status">
          <SyncIcon color="primary" />
        </IconButton>
        <IconButton aria-label="save logs">
          <DriveFolderUploadIcon color="primary" />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default DeviceHistoryCard
